<template>
    <template v-if="$store.state.user.auth.is_signed_in">
        <div class="layout-wrap">
            <div class="layout-aside">
                <div class="aside-header bg-primary">
                    <h1 class="aside-header-branding">ResaleTrap v3</h1>
                    <button class="aside-header-toggle"><i class="bi bi-list"></i></button>
                </div>
                <nav>
                    <ul class="nav-list">
                        <li class="nav-item nav-dropdown" :class="{active: isActive('inventory', ['inventory/closed'])}">
                            <router-link :to="{ name: 'PageInventory' }"><i class="bi bi-search"></i> 監視中の商品</router-link>
                            <ul class="nav-dropdown-list">
                                <li class="nav-item"><router-link :to="{ name: 'PageInventory' }">全て</router-link></li>
                                <template v-if="$store.getters['user/auth/canViewAllSku']()">
                                <li class="nav-item" v-for="user of users" :key="user.user_id">
                                    <router-link :to="{ name: 'PageInventory', params: {user: user.user_id} }">{{ user.user_name }}</router-link>
                                </li>
                                </template>
                            </ul>
                        </li>
                        <li class="nav-item nav-dropdown" :class="{active: isActive('inventory/closed')}">
                            <router-link :to="{ name: 'PageInventoryClosed' }"><i class="bi bi-folder"></i> 終了した商品</router-link>
                            <ul class="nav-dropdown-list">
                                <li class="nav-item"><router-link :to="{ name: 'PageInventoryClosed' }">全て</router-link></li>
                                <template v-if="$store.getters['user/auth/canViewAllSku']()">
                                <li class="nav-item" v-for="user of users" :key="user.user_id">
                                    <router-link :to="{ name: 'PageInventoryClosed', params: {user: user.user_id} }">{{ user.user_name }}</router-link>
                                </li>
                                </template>
                            </ul>
                        </li>
                        <li class="nav-item" :class="{active: isActive('entry')}">
                            <router-link :to="{ name: 'PageEntry' }"><i class="bi bi-box-seam"></i> 出品</router-link>
                        </li>
                        <li class="nav-item" :class="{active: isActive('stats')}">
                            <router-link :to="{ name: 'PageStats' }"><i class="bi bi-bar-chart-fill"></i> 集計</router-link>
                        </li>
                        <li class="nav-item nav-dropdown" :class="{active: isActive('setting')}">
                            <router-link :to="{ name: 'PageSettingInventory' }"><i class="bi bi-gear"></i> 設定</router-link>
                            <ul class="nav-dropdown-list">
                                <li class="nav-item"><router-link :to="{ name: 'PageSettingInventory' }">出品設定</router-link></li>
                                <li class="nav-item"><router-link :to="{ name: 'PageSettingForbiddenAsin' }">出品禁止ASIN</router-link></li>
                                <li class="nav-item"><router-link :to="{ name: 'PageSettingForbiddenMaker' }">出品禁止メーカー</router-link></li>
                                <li class="nav-item"><router-link :to="{ name: 'PageSettingIgnoredDealer' }">除外出品者</router-link></li>
                                <li class="nav-item"><router-link :to="{ name: 'PageSettingIgnoredKeyword' }">除外キーワード</router-link></li>
                                <li class="nav-item"><router-link :to="{ name: 'PageSettingSpapi' }">SP-API</router-link></li>
                            </ul>
                        </li>
                        <li class="nav-item" :class="{active: isActive('manage/user')}" v-if="$store.getters['user/auth/canManageUser']()">
                            <router-link :to="{ name: 'PageManageUser' }"><i class="bi bi-people-fill"></i> ユーザ管理</router-link>
                        </li>
                        <li class="nav-item" :class="{active: isActive('payment')}" v-if="$store.getters['user/auth/canManagePayment']()">
                            <router-link :to="{ name: 'PagePayment' }"><i class="bi bi-credit-card"></i> お支払い</router-link>
                        </li>
                        <li class="nav-item" :class="{active: isActive('preference')}">
                            <router-link :to="{ name: 'PagePreference' }"><i class="bi bi-person-circle"></i> アカウント</router-link>
                        </li>
                        <li class="nav-item"><a href="#" @click="signOut()"><i class="bi bi-door-open"></i> ログアウト</a></li>
                    </ul>
                </nav>
            </div>
            <div class="layout-main">
                <div class="container">
                    <router-view/>
                </div>
            </div>
        </div>

    </template>

    <template v-else>
        <nav class="navbar navbar-dark bg-dark text-white mb-3">
            <div class="container-fluid">
                ResaleTrap v3
            </div>
        </nav>

        <div class="container">
            <router-view/>
        </div>
    </template>

    <screen-loader v-if="loading > 0"></screen-loader>

    <message-dialog ref="message_dialog" :title="dialog.title" :message="dialog.message" :icon="dialog.icon"></message-dialog>
</template>

<script>
import ScreenLoader from '@/shared/components/tool/ScreenLoader.vue'
import MessageDialog from '@/shared/components/tool/MessageDialog.vue'
import User from '@/shared/models/entities/user'

export default {
    name: 'App',
    components: {
        ScreenLoader,
        MessageDialog
    },
    provide() {
        // 参考 emit/props vs provide/inject
        // https://cloudsmith.co.jp/blog/frontend/2020/12/1656030.html
        return {
            startScreenLoading: this.startScreenLoading,
            endScreenLoading: this.endScreenLoading,
            quitScreenLoading: this.quitScreenLoading,
            showMessage: this.showMessage,
            showErrorMessage: this.showErrorMessage,
            fetchUsers: this.fetchUsers,
        }
    },
    data() {
        return {
            loading: 0,
            dialog: {
                title: null,
                message: null,
                icon: null,
            },
            nav_collapse: false,
            users: [],
        }
    },
    mounted() {
        window.addEventListener('show_message', (event) => {
            this.showMessage(event.detail.title, event.detail.message);
        });
        window.addEventListener('show_error_message', (event) => {
            this.showErrorMessage(event.detail.title, event.detail.message);
        });

        if (this.$store.state.user.auth.is_signed_in) {
            this.fetchUsers();
        }
    },
    methods: {
        fetchUsers() {
            this.$http.get('/users')
            .then(response => {
                this.users = response.data.map((row) => new User(row));
            })
        },
        signOut() {
            this.$store.commit('user/auth/signOut');
            this.$router.push({ name: 'PageSignin' });
        },
        startScreenLoading() {
            this.loading++;
        },
        endScreenLoading() {
            this.loading--;
        },
        quitScreenLoading() {
            this.loading = 0;
        },
        showMessage(title, message) {
            this.dialog.title = title ?? '完了';
            this.dialog.message = message;
            this.dialog.icon = 'success';
            this.$refs.message_dialog.show();
        },
        showErrorMessage(title, message) {
            this.dialog.title = title ?? 'エラー';
            this.dialog.message = message;
            this.dialog.icon = 'error';
            this.$refs.message_dialog.show();
        },
        isActive(key, excepts) {
            if (Array.isArray(excepts)) {
                for (let except of excepts) {
                    if (this.$route.path.indexOf('/' + except) === 0) {
                        return false;
                    }
                }
            }
            return (this.$route.path.indexOf('/' + key) === 0);
        }
   },
   computed: {
        user_name() {
            return this.$store.state.user.auth.user_name;
        }
    }
}
</script>

<style>
.container {
    padding-top: 1em;
    padding-bottom: 1em;
}
.layout-wrap {
    display: flex;
    justify-content: space-between;
}
.layout-aside {
    width: 200px;
    min-height: 100vh;
    background: #2f4050;
}
.layout-main {
    width: calc(100% - 200px);
}

.aside-header {
    display: flex;
}
.aside-header-branding {
    width: calc(100% - 40px);
    margin-bottom: 0;
    font-size: 1rem;
    padding: 0.8rem;
    color: #FFF;
}
.aside-header-toggle {
    width: 40px;
    border: none;
    background: transparent;
    font-size: 1.5rem;
    color: #FFF;
    font-weight: bold;
}
.nav-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}
.nav-dropdown-list {
    display: none;
    margin: 0;
    padding-left: 1rem;
    list-style-type: none;
}
.active .nav-dropdown-list {
    display: block;
}
.nav-item {
    border-left: 3px solid #2f4050;
}
.nav-item.active {
    border-left: 3px solid var(--bs-primary);
}
.nav-item a,
.nav-item span {
    display: block;
    padding: 0.8rem;
    font-size: 0.8rem;
    font-weight: bold;
    color: #a7b1c2;
    text-decoration: none;
}
</style>
