import { isNud } from '@/shared/utilities/typing';

export const condition = {
    namespaced: true,
    state: {
        conditions: []
        /*
        例)
        conditions: [
            {
                page: 'PageOrderList',
                condition: {...}
            },
            {
                page: 'PageLicenseList',
                condition: {...}
            },
        ]
        */
    },
    mutations: {
        setSearchCondition(state, param) {
            state.conditions = state.conditions.filter((row) => {
                return row.page !== param.page;
            });

            state.conditions.push(param);
        }
    },
    getters: {
        merge: (state) => (page_name, default_condition) => {
            let stored_condition = state.conditions.find((condition) => {
                return condition.page === page_name
            });

            if (isNud(stored_condition)) {
                return default_condition;
            }

            //マージして返す (keyはdefault_conditionに合わせ、stored_conditionの値を上書きする)
            let merged_condition = {};
            for (let key in default_condition) {
                if (key in stored_condition.condition && stored_condition.condition[key] !== null) {
                    merged_condition[key] = stored_condition.condition[key];
                } else {
                    merged_condition[key] = default_condition[key];
                }
            }
            return merged_condition;
        }
    }
}
