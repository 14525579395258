import axios from 'axios'
import store from '@/user/store';
import router from '@/user/router';
import { now } from '@/shared/utilities/date-format';

const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URI
})

http.interceptors.request.use(
    async (config) => {
        if (store.state.user.auth.access_token_expired < now()) {
            //トークンのリフレッシュ
            await axios.post(process.env.VUE_APP_API_BASE_URI + '/signin/refresh', {
                refresh_token: store.state.user.auth.refresh_token
            }).then((response) => {
                store.commit('user/auth/setAccessToken', response.data);
            }).catch(() => {
                //敢えて捕捉せず、メインリクエストで401にさせる
            });
        }

        config.headers = {
            Authorization: 'Bearer ' + store.state.user.auth.access_token,
            'X-Requested-With': "XMLHttpRequest",
        }
        return config;
    }
)

http.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response && error.response.status === 400) {
            let message_detail = {
                title: 'エラー',
                message: error.response.data.message,
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else if (error.response && error.response.status === 401) {
            // TODO;トークン再取得処理
            let message_detail = {
                title: 'ログイン切れ',
                message: 'ログインの有効期間が切れました。ログイン画面に移動します',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

            store.commit('user/auth/signOut');
            router.push('/signin');

        } else if (error.response && error.response.status === 403) {
            let message_detail = {
                title: '権限エラー',
                message: 'この操作をする権限がありません。必要な場合、管理者に権限の変更を依頼してください。',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else if (error.response && error.response.status === 404) {
            let message_detail = {
                title: '404エラー',
                message: 'データが見つかりません',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else if (error.response && error.response.status === 422) {
            let errors = error.response.data.errors;
            let errorMessages = [];
            Object.keys(errors).forEach((key) => {
                let array_order = key.match(new RegExp(/\.([0-9]+)\./));
                if (array_order) {
                    //ネストしている場合は何行目が間違っているか表示する（多重ネストは非対応です）
                    let row_number = parseInt(array_order[1]) + 1;
                    errorMessages.push(`${row_number}行目の${errors[key]}`);
                } else {
                    errorMessages.push(errors[key]);
                }
            });

            let message_detail = {
                title: '入力内容に誤りがあります',
                message: errorMessages.join("\n"),
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);

        } else {
            let message_detail = {
                title: 'エラー',
                message: 'システムエラーが発生しました',
                icon: 'error'
            }
            let event = new CustomEvent('show_error_message', {detail: message_detail});
            window.dispatchEvent(event);
        }

        return Promise.reject(error);
    }
)

export default http
