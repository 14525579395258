import Enum from './enum'

class AccountIsActive extends Enum {
    static ACTIVE = 1; // 利用中
    static INACTIVE = 0; // 停止中

    static values() {
        return {
            [this.ACTIVE]: '利用中',
            [this.INACTIVE]: '停止中',
        }
    }
}

export default AccountIsActive;
