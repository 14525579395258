import Enum from './enum'

class Role extends Enum {
    static OWNER = 1; // 契約管理者
    static MANAGER = 2; // 管理者
    static STAFF = 9; // 一般
    static TRAINEE = 11; // 見習い

    static values() {
        return {
            [this.OWNER]: '契約管理者',
            [this.MANAGER]: '管理者',
            [this.STAFF]: '一般',
            [this.TRAINEE]: '見習い',
        }
    }

    static options() {
        let optionable = [
            this.MANAGER,
            this.STAFF,
            this.TRAINEE,
        ];

        return optionable.map((key) => {return {key: key, label: this.get(key)}});
    }
}

export default Role;
