import Account from "@/shared/models/entities/account";
import Role from "@/shared/models/enums/role";
import { isNud } from '@/shared/utilities/typing';
import { dateYmd } from '@/shared/utilities/date-format';

/**
 * ユーザー エンティティ
 */
class User {
    user_id;
    account;
    user_name;
    email;
    password;
    can_signin;
    is_draft_only;
    role;
    latest_signin_datetime;


    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.user_id = properties.user_id;
        this.account = new Account(properties.account);
        this.user_name = properties.user_name;
        this.email = properties.email;
        this.password = null; //セットしない
        this.can_signin = properties.can_signin;
        this.is_draft_only = properties.is_draft_only;
        this.role = properties.role;
        this.latest_signin_datetime = properties.latest_signin_datetime;
    }

    get role_display() {
        return Role.get(this.role);
    }

    get latest_signin_datetime_display() {
        return this.latest_signin_datetime ? dateYmd(this.latest_signin_datetime) : ' - ';
    }

}

export default User;
