import Role from '@/shared/models/enums/role'
import Account from '@/shared/models/entities/account'

export const auth = {
    namespaced: true,
    state: {
        account: null,
        user_name: null,
        email: null,
        role: null,
        access_token: null,
        access_token_expired: null,
        access_token_issued: null,
        refresh_token: null,
        refresh_token_expired: null,
        refresh_token_issued: null,
        is_signed_in: false,
    },
    mutations: {
        setAccessToken (state, param) {
            state.account = new Account(param.account);
            state.user_name = param.user_name;
            state.email = param.email;
            state.role = param.role;
            state.access_token = param.access_token;
            state.access_token_expired = param.access_token_expired;
            state.access_token_issued = param.access_token_issued;
            state.refresh_token = param.refresh_token;
            state.refresh_token_expired = param.refresh_token_expired;
            state.refresh_token_issued = param.refresh_token_issued;
            state.is_signed_in = true;
        },
        signOut (state) {
            state.account = null;
            state.user_name = null;
            state.email = null;
            state.role = null;
            state.access_token = null;
            state.access_token_expired = null;
            state.access_token_issued = null;
            state.refresh_token = null;
            state.refresh_token_expired = null;
            state.refresh_token_issued = null;
            state.is_signed_in = false;
        },
        setAccount (state, param) {
            state.account = new Account(param);
        },
    },
    getters: {
        canViewAllSku: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        canReleaseSku: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        canRetryError: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        canManageUser: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        canManageSpapi: (state) => () => {
            return (state.role === Role.OWNER || state.role === Role.MANAGER);
        },
        canManagePayment: (state) => () => {
            return (state.role === Role.OWNER);
        },
    }
}
