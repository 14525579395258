// ヘルパー関数プラグイン化

import { dateFormat, dateYmd, dateMd, dateYmdHi, dateYmdForInputDate, today, now, dateMonthPeriod } from './date-format';
import { numberFormat } from './text-format';
import { isNud } from './typing';

export default {
    install: (app) => {
        app.config.globalProperties.$helper = {
            date: dateFormat,
            ymd: dateYmd,
            md: dateMd,
            dmp: dateMonthPeriod,
            ymdForInputDate: dateYmdForInputDate,
            ymdhi: dateYmdHi,
            today: today,
            now: now,
            number: numberFormat,
            isNud: isNud,
        }
    }
}
