import { createApp } from 'vue';
//import axios from 'axios';
import VueAxios from 'vue-axios';

import http from './http'
import store from './store';
import router from './router';
import helper from '@/shared/utilities/helper';

import App from './App.vue';

import "bootstrap";
//import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/app.scss";
import 'bootstrap-icons/font/bootstrap-icons.css'

createApp(App)
//.use(VueAxios, axios)
//.use(http)
.use(VueAxios, http)
.use(store)
.use(router)
.use(helper)
.mount('#app');
//createApp(App).mount('#app');
