import Role from "@/shared/models/enums/role";
import { isNud } from '@/shared/utilities/typing';
import { dateYmd } from '@/shared/utilities/date-format';

/**
 * オーナーユーザー エンティティ
 */
class OwnerUser {
    user_id;
    user_name;
    email;

    constructor(properties) {
        if (isNud(properties)) {
            properties = {};
        }

        this.user_id = properties.user_id;
        this.user_name = properties.user_name;
        this.email = properties.email;
    }

    get role_display() {
        return Role.get(this.role);
    }

    get latest_signin_datetime_display() {
        return this.latest_signin_datetime ? dateYmd(this.latest_signin_datetime) : ' - ';
    }

}

export default OwnerUser;
