<template>
    <button :type="type" class="btn btn-primary" :class="size">
        <i class="bi" :class="icon"></i>
        {{ text }}
    </button>
</template>

<script>
export default {
    name: 'ButtonExecEdit',
    props: {
        text: {
            type: String,
            default: '保存',
        },
        icon: {
            type: String,
            default: 'bi-check-circle',
        },
        type: {
            type: String,
            default: 'submit',
        },
        size: {
            type: String,
            default: 'btn-lg'
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
