import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import { condition } from './condition.js'
import { auth } from './auth.js'

const store = createStore({
    modules: {
        user: {
            namespaced: true,
            modules: {
                auth,
                condition,
            }
        }
    },
    plugins: [
        createPersistedState({
            key: 'resaletrap',
            /*
            paths: [
                'auth.isLoggedIn',
                'master.dataSelected'
            ],
            */
            //storage: window.sessionStorage
        })
    ]
})

export default store
